<template>
  <div>
    <NavigationBar titel="Archiv" :tabs="tabs"></NavigationBar>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
export default {
  name: "accounting-archive",
  components: {
    NavigationBar,
  },
  data() {
    return {
      activeTab: `archive/time-sheets`,
      tabs: [
        { id: 1, name: "Stundenmeldungen", route: `time-sheets` },
        { id: 2, name: "Abrechnungszettel", route: `payslips`, disabled: true },
        { id: 3, name: "Abrechnungsstapel", route: `payroll`, disabled: true },
      ],
    };
  },
};
</script>
