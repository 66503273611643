<template>
  <div>
    <section>
      <TippCard
        hintID="Stundenabrechnung_Archiv_Stundenmeldungen_1_V1"
        text="Im Archiv können festgeschriebene und archivierte Stundenmeldungen eingesehen werden. Abfragen können dabei auf einen bestimmten Zeitraum begrenzt werden."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-archive"
                title="Archivierte Stundenmeldungen"
              >
              </Subheader>
              <div class="mt-7">
                <v-card :loading="loading">
                  <v-card-text>
                    <v-container class="mb-n10">
                      <v-form
                        @submit.prevent="startQuery"
                        v-model="formIsValid"
                        ref="form"
                      >
                        <v-row>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.start"
                              type="date"
                              label="Startdatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :disabled="loading"
                              clearable
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.end"
                              type="date"
                              label="Enddatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required]"
                              required
                              :disabled="loading"
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="success"
                      :disabled="loading"
                      @click="startQuery()"
                      class="mr-5"
                      >Abfrage starten</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </div>
              <div class="mt-7">
                <time-sheets-data-table
                  :timeSheets="archiveMatched"
                  showActionBtn
                ></time-sheets-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import TimeSheetsDataTable from "@/components/accounting/time-sheets/TimeSheetsDataTable.vue";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_ARCHIVED_TIME_SHEETS } from "@/store/action-types.js";

export default {
  name: "availability-archive-tab-leaves",
  components: {
    Subheader,
    TippCard,
    SupportTools,
    TimeSheetsDataTable,
  },
  data() {
    return {
      search: "",
      sortDesc: true,
      sortBy: "title",
      groupBy: "groupBy",
      groupDesc: true,

      // Table Display Settings
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },

      query: {
        range: {
          start: null,
          end: null,
        },
        status: [
          "toBeReviewed",
          "approved",
          "rejected",
          "archived",
          "completed",
        ],
      },
      formIsValid: false,
      loading: true,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  computed: {
    archivedTimeSheets() {
      return this.$store.state.accounting.archivedTimeSheets;
    },
    archiveMatched() {
      return this.archivedTimeSheets.map((timeSheet) => ({
        ...timeSheet,
        // groupBy: this.groupByDateText(timeSheet.duration.start.timestamp.toDate()),
      }));
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "archiveMatched", data: this.archiveMatched },
        { title: "query", data: this.query },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.query.range.end = new Date().toISOString().substr(0, 10);

      this.loading = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    startQuery() {
      this.validate();
      if (this.formIsValid) {
        if (this.query.range.start == "") {
          this.query.range.start = null;
        }
        this.$store.dispatch(`${ACCOUNTING}${GET_ARCHIVED_TIME_SHEETS}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            range: {
              start: this.query.range.start,
              end: this.query.range.end,
            },
          },
        });
      } else {
        alert("Fehler: Es ist ein Enddatum für die Abfrage erforderlich.");
      }
    },

    groupByDateText(date) {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const monthLong = date.toLocaleString("de-DE", { month: "long" });
      return year + "-" + month + "_" + monthLong + " " + year;
    },
  },
};
</script>

<style></style>
